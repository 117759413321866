import React, {
  useReducer,
  useCallback,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { If } from 'react-if';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  TextField,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
  DialogTitle,
  Typography,
  Dialog,
  Tooltip,
  Alert,
} from '@mui/material';
import { captureException } from '@sentry/browser';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import reducer, {
  actionTypes,
  getInitialState,
} from './AddonDialogReducer';
import AddonsCapabilitiesComponent from './AddonCapabilities';
import { get as registryGet } from '../../lib/appRegistry';
import useMobileDialog from '../hooks/useMobileDialog';
import useNotifier from '../hooks/useNotifier';
import ConfirmDialog from '../Dialogs/confirmDialog';

const addonCapabilities = [
  {
    key: 'settings',
    title: 'Settings',
    description: 'When adding an addon to the space, an iframe with the plugin configuration will be shown',
  },
  {
    key: 'card_body_section',
    title: 'Iframe in the card (card_body_section)',
    description: 'Section of the card where the iframe will be shown',
  },
  {
    key: 'card_buttons',
    title: 'Buttons in the card (card_buttons)',
    description: 'Section of the card, inside which addon buttons will be displayed',
  },
  {
    key: 'card_facade_badges',
    title: 'Card facade badges (card_facade_badges)',
    description: 'Small badges, placed on card facade on space',
  },
];

const TestDomain = styled(Typography)(
  ({ theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

const HelpIcon = styled(HelpOutlineIcon)(
  () => ({
    marginLeft: 3,
    cursor: 'pointer',
  }),
);

const CompanyDomain = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const validateState = (state) => {
  const isNameValid = !_.isEmpty(_.trim(state.name));

  if (!isNameValid) {
    return 'Enter addon name';
  }

  if (_.isEmpty(_.trim(state.iframe_initial_url))) {
    return 'Enter iframe connector URL';
  }

  try {
    const parsedUrl = new URL(state.iframe_initial_url);

    if (!_.includes(['http:', 'https:'], parsedUrl.protocol)) {
      return 'URL should be https or http for localhost';
    }

    if (parsedUrl.hostname !== 'localhost' && parsedUrl.protocol === 'http:') {
      return 'URL must be https (or http for localhost)';
    }
  } catch (err) {
    return 'Enter valid iframe connector URL';
  }

  if (state.icon_url) {
    try {
      const parsedUrl = new URL(state.icon_url);
      if (!_.includes(['http:', 'https:'], parsedUrl.protocol)) {
        return 'URL should be https or http for localhost';
      }

      if (parsedUrl.hostname !== 'localhost' && parsedUrl.protocol === 'http:') {
        return 'URL must be https (or http for localhost)';
      }
    } catch (err) {
      return 'Enter valid icon URL';
    }
  }
  if (_.isEmpty(_.trim(state.domain))) {
    return 'Enter test company domain';
  }

  return null;
};

const getChangedFields = (currentState, initialState) => {
  const changedFields = {};
  _.forEach(_.keys(currentState), (key) => {
    if (_.isArray(currentState[key]) && _.isArray(initialState[key])) {
      if (!_.isEqual(_.sortBy(currentState[key]), _.sortBy(initialState[key]))) {
        changedFields[key] = currentState[key];
      }
      return;
    }

    if (currentState[key] !== initialState[key]) {
      changedFields[key] = currentState[key];
    }
  });
  return changedFields;
};

function UserAddonDialog(props) {
  const {
    onSave,
    onClose,
    addon,
  } = props;

  const fullScreen = useMobileDialog();
  const { enqueueErrorSnackbar } = useNotifier();
  const { hostName } = registryGet('config');
  const [confirmLeaveDialogOpen, setConfirmLeaveDialogOpen] = useState(false);

  const [state, dispatch] = useReducer(reducer, getInitialState(addon));
  const initialState = useRef(state);

  const changedFields = getChangedFields(state, initialState.current);
  const isDirty = !_.isEmpty(changedFields);

  const handleUpdateName = (e) => {
    dispatch({ type: actionTypes.updateName, data: e.target.value });
  };

  const handleUpdateInitialUrl = (e) => {
    dispatch({ type: actionTypes.updateInitialUrl, data: e.target.value });
  };

  const handleUpdateIconUrl = (e) => {
    dispatch({ type: actionTypes.updateIconUrl, data: e.target.value });
  };

  const handleUpdateOverview = (e) => {
    dispatch({ type: actionTypes.updateOverview, data: e.target.value });
  };

  const handleUpdateDescription = (e) => {
    dispatch({ type: actionTypes.updateDescription, data: e.target.value });
  };
  const handleUpdateDomain = (e) => {
    dispatch({ type: actionTypes.updateTestDomain, data: e.target.value });
  };

  const handleSave = () => {
    const error = validateState(state);

    if (error) {
      enqueueErrorSnackbar(error);
      return;
    }

    dispatch({ type: actionTypes.setProcessing });
    onSave(changedFields, addon ? addon.id : null)
      .then(() => {
        dispatch({ type: actionTypes.finishProcessing });
        onClose();
      })
      .catch((err) => {
        dispatch({ type: actionTypes.finishProcessing });
        if (!err.request) {
          enqueueErrorSnackbar('Something went wrong, but we have already received information about it and working on it');
          captureException(err);
        } else if (!err.response) {
          enqueueErrorSnackbar('There is a problem processing your request. Please check your internet connection.');
        } else if (err.response.status === 403) {
          enqueueErrorSnackbar(`You do not have sufficient rights to add the addon to account "${state.domain}.${hostName}""`);
        } else if (_.startsWith(err.response.status, 4)) {
          enqueueErrorSnackbar(_.get(err, 'response.data.message') || err.response.data);
        } else if (_.startsWith(err.response.status, 5)) {
          enqueueErrorSnackbar('An error occurred while saving addon data.');
          captureException(err);
        } else {
          enqueueErrorSnackbar('An error occurred while saving addon data.');
        }
      });
  };

  const toggleCapability = useCallback((capability) => {
    dispatch({ type: actionTypes.toggleCapability, capability });
  }, []);

  const handleClose = () => {
    if (isDirty) {
      setConfirmLeaveDialogOpen(true);
      return;
    }
    onClose();
  };

  const closeConfirmDialog = () => {
    setConfirmLeaveDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        onClose={handleClose}
      >
        <DialogTitle>
          {addon ? `Editing addon «${addon.name}»` : 'Creating an addon'}
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            value={state.name}
            label="Addon name"
            placeholder="Enter name"
            onChange={handleUpdateName}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            value={state.iframe_initial_url}
            label="Iframe connector url"
            placeholder="Enter url"
            onChange={handleUpdateInitialUrl}
          />

          <TestDomain>
            Test company domain*
            <Tooltip title="The domain of the company where this addon will be available for testing">
              <HelpIcon color="action" fontSize="small" />
            </Tooltip>
          </TestDomain>
          <Alert severity="warning">
            {`To be able to add an addon to the test domain, you must have a ${hostName} account with the same email address that was used for registration on the developer portal and access to Administrative section "Addons"`}
          </Alert>
          <CompanyDomain
            required
            fullWidth
            value={state.domain}
            onChange={handleUpdateDomain}
            variant="outlined"
            placeholder="Enter domain"
            size="small"
            InputProps={{
              endAdornment: (
                <Typography color="textSecondary">{`.${hostName}`}</Typography>
              ),
            }}
          />

          <Typography variant="subtitle1" color="textSecondary">
            Capabilities
          </Typography>

          <AddonsCapabilitiesComponent
            capabilities={state.capabilities || []}
            toggleCapability={toggleCapability}
            capabilityVariants={addonCapabilities}
          />

          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            value={state.icon_url}
            label="Addon icon url"
            placeholder="Enter valid url"
            onChange={handleUpdateIconUrl}
          />

          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            value={state.overview}
            label="Overview"
            placeholder="Enter short overview"
            onChange={handleUpdateOverview}
          />

          <TextField
            fullWidth
            multiline
            maxRows={5}
            margin="normal"
            variant="outlined"
            size="small"
            value={state.description}
            label="Description"
            placeholder="Enter description"
            onChange={handleUpdateDescription}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={state.is_processing}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleSave}
            disabled={state.is_processing || !isDirty}
          >
            Save
            <If condition={state.is_processing}>
              <CircularProgress size={18} />
            </If>
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirmLeaveDialogOpen}
        maxWidth="xs"
        onSubmit={closeConfirmDialog}
        onCancel={onClose}
        cancelLabel="close"
        confirmLabel="don't close"
      >
        The changes made will not be saved, are you sure you want to close the current addon?
      </ConfirmDialog>

    </>

  );
}

UserAddonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  addon: PropTypes.object,
};

UserAddonDialog.defaultProps = {
  addon: null,
};

export default UserAddonDialog;
