import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {
  Typography,
  Box,
  IconButton,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Link,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import urls from '../../shared/urls';
import requestWithAuth from '../../utils/requestWithAuth';

const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '5%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10vh',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
  },
  cardInner: {
    width: '400px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardInfo: {
    width: '90%',
    minHeigth: '100px',
  },
  header: {
    color: 'grey',
    margin: theme.spacing(2),
    marginTop: 0,
  },
  inputFields: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  mainArea: {
    minWidth: '400px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  passwordField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
}));

function Reset() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [highlightError, setHighlightError] = useState(false);

  const handleChangePassword = (ev) => {
    setPassword(ev.target.value);
  };
  const handleChangePasswordConfirm = (ev) => {
    setPasswordConfirm(ev.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((prev) => !prev);
  };

  const isDataValid = (firstPassword, secondPassword) => {
    if (!firstPassword || firstPassword !== secondPassword) {
      setHighlightError(true);
      return false;
    }
    return true;
  };

  const onResetPassword = () => {
    // eslint-disable-next-line
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get('key');
    if (!isDataValid(password, passwordConfirm)) {
      return;
    }
    requestWithAuth.post(urls.reset, {
      key,
      password,
    }).then(() => {
      navigate('/login');
    }).catch((err) => {
      let message = '';

      if (!err.request) {
        message = 'Что-то пошло не так, но мы уже получили об этом информацию и работаем над этим';
        captureException(err);
      } else if (!err.response) {
        message = 'Возникла проблема с обработкой вашего запроса. Проверьте подключение к интернету.';
      } else if (err.response.data?.message) {
        message = err.response.data?.message;
      } else if (err.response.status === 404) {
        message = 'Недействительная ссылка для восстановления пароля';
      } else {
        message = 'Произошла ошибка при восстановлении пароля';
      }

      if (_.startsWith(err.response.status, 5)) {
        captureException(err);
      }

      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
        action: (
          <IconButton
            onClick={() => {
              closeSnackbar();
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      });
    });
  };

  return (
    <Box
      className={classes.mainBoxWrapper}
    >
      <div className={classes.mainArea}>
        <Card className={classes.cardInner}>
          <CardContent className={classes.cardInfo}>
            <div className={classes.header}>
              <Typography variant="h5" gutterBottom>
                Восстановление пароля
              </Typography>
            </div>
            <Divider />
            <div className={classes.passwordField}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password" error={!password && highlightError} required>Новый пароль</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  placeholder=""
                  label="Новый пароль"
                  variant="outlined"
                  value={password}
                  onChange={handleChangePassword}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  required
                  error={!password && highlightError}
                />
              </FormControl>
            </div>
            <div className={classes.passwordField}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password" error={highlightError && (!passwordConfirm || password !== passwordConfirm)} required>
                  Подтвердите пароль
                </InputLabel>
                <OutlinedInput
                  id="passwordConfirm"
                  name="password"
                  autoComplete="new-password"
                  placeholder=""
                  label="Подтвердите пароль"
                  variant="outlined"
                  value={passwordConfirm}
                  onChange={handleChangePasswordConfirm}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        edge="end"
                      >
                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  required
                  error={highlightError && (!passwordConfirm || password !== passwordConfirm)}
                />
              </FormControl>
            </div>
            <Box className={classes.buttonsWrapper}>
              <Button
                variant="outlined"
                onClick={onResetPassword}
                className={classes.buttons}
                disabled={!password}
              >
                Обновить пароль
              </Button>
            </Box>
          </CardContent>
        </Card>

        <div className={classes.linksWrapper}>
          <Link
            href="/"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            Обратно в developers
          </Link>
          <Link
            href="/login"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            На страницу авторизации
          </Link>
        </div>
      </div>
    </Box>
  );
}

export default Reset;
