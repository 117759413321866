import React, { useState, useEffect } from 'react';
import { If, Then, Else } from 'react-if';
import {
  Typography,
  IconButton,
  Box,
  Link,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import requestWithAuth from '../../utils/requestWithAuth';

import urls from '../../shared/urls';
import { addValue } from '../../utils/storage';

const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '5%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '10vh',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
  },
  header: {
    color: 'grey',
    margin: theme.spacing(2),
    marginTop: 0,
  },
  cardInner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardInfo: {
    maxWidth: '400px',
    minHeigth: '100px',
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  marginWrapper: {
    margin: theme.spacing(2),
  },
  mainArea: {
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWidth: '90%',
    },
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

function SuccessActivateAccount() {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isActivated, setIsActivated] = useState(false);

  useEffect(() => {
    // Функция для парсинга query parameters
    // eslint-disable-next-line
    const queryParams = new URLSearchParams(window.location.search);
    const uid = queryParams.get('uid');
    if (uid) {
      requestWithAuth.post(urls.activate, { uid })
        .then((res) => {
          setIsActivated(true);
          addValue('user', JSON.stringify({
            user: res.data,
          }));
          navigate('/');
        }).catch((err) => {
          let message = '';

          if (!err.request) {
            message = 'Что-то пошло не так, но мы уже получили об этом информацию и работаем над этим';
          } else if (!err.response) {
            message = 'Возникла проблема с обработкой вашего запроса. Проверьте подключение к интернету.';
          } else if (err.response.data?.message) {
            message = err.response.data?.message;
          } else {
            message = 'Произошла ошибка при активации аккаунта';
          }
          enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 5000,
            action: (
              <IconButton
                onClick={() => {
                  closeSnackbar();
                }}
              >
                <CloseIcon />
              </IconButton>
            ),
          });
        });
    }
  }, []);

  return (
    <Box
      className={classes.mainBoxWrapper}
    >
      <div className={classes.mainArea}>
        <Card className={classes.cardInner}>
          <CardContent className={classes.cardInfo}>
            <div className={classes.header}>
              <Typography variant="h6">
                Ваш аккаунт успешно активирован
              </Typography>
            </div>
            <Divider />
            <div className={classes.marginWrapper}>
              <If condition={isActivated}>
                <Then>
                  <Typography variant="h7">
                    Активация аккаунта в процессе. Пожалуйста, подождите
                  </Typography>
                </Then>
                <Else>
                  <Typography variant="h7">
                    Вы можете авторизоваться
                  </Typography>
                </Else>
              </If>

            </div>
          </CardContent>
        </Card>
        <Divider />
        <div className={`${classes.linksWrapper}`}>
          <Link
            href="/"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            Обратно в developers
          </Link>
          <Link
            href="/login"
            style={{
              color: 'grey',
              textDecorationColor: 'grey',
            }}
          >
            На страницу авторизации
          </Link>
        </div>
      </div>
    </Box>
  );
}

export default SuccessActivateAccount;
