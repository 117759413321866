import React, { useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import {
  Typography,
  Box,
  TextField,
  IconButton,
  Button,
  Link,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { addValue } from '../../utils/storage';
import requestWithAuth from '../../utils/requestWithAuth';
import urls from '../../shared/urls';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '30vh',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '95vh',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  inputFields: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  mainArea: {
    minWidth: '400px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90%',
    },
  },
  passwordField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  footerWrapper: {
    marginTop: theme.spacing(10),
    width: 400,
    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
    },
  },
}));

function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeEmail = (ev) => {
    setEmail(ev.target.value);
  };
  const handleChangePassword = (ev) => {
    setPassword(ev.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onLoginHandler = useCallback(() => {
    requestWithAuth.post(urls.login, {
      username: email,
      password,
    }).then((res) => {
      addValue('user', JSON.stringify({
        user: res.data,
      }));
      navigate('/');
    }).catch((err) => {
      let message = '';

      if (!err.request) {
        message = 'Что-то пошло не так, но мы уже получили об этом информацию и работаем над этим';
        captureException(err);
      } else if (!err.response) {
        message = 'Возникла проблема с обработкой вашего запроса. Проверьте подключение к интернету.';
      } else if (err.response.data?.message) {
        message = err.response.data?.message;
      } else if (err.response.status === 401) {
        message = 'Не удалось авторизоваться. Неправильный email почты или пароль';
      } else if (err.response.status === 400) {
        message = 'Не удалось авторизовать пользователя';
      } else if (err.response.status === 403) {
        navigate('/verify-email', {
          state: {
            email,
            info: {
              title: 'Подтвердите аккаунт',
              content: 'Пожалуйста, проверьте почту и подтвердите ваш аккаунт',
            },
          },
        });
        return;
      } else {
        message = 'Произошла ошибка при авторизации';
      }

      if (_.startsWith(err.response.status, 5)) {
        captureException(err);
      }

      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
        action: (
          <IconButton
            onClick={() => {
              closeSnackbar();
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      });
    });
  }, [email, password]);

  return (
    <div>
      <Box
        className={classes.mainBoxWrapper}
      >
        <div className={classes.mainArea}>
          <Typography variant="h4" gutterBottom>
            Авторизация
          </Typography>
          <TextField
            id="email"
            name="username"
            autoComplete="username"
            variant="outlined"
            label="Email"
            margin="normal"
            onChange={handleChangeEmail}
            className={classes.inputFields}
            required
          />
          <div className={classes.passwordField}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password" required>Пароль</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                autoComplete="password"
                placeholder=""
                label="Пароль"
                variant="outlined"
                value={password}
                onChange={handleChangePassword}
                type={showPassword ? 'text' : 'password'}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
                required
              />
            </FormControl>
          </div>
          <Box className={classes.buttonsWrapper}>
            <Button
              variant="outlined"
              type="submit"
              onClick={onLoginHandler}
              className={classes.buttons}
            >
              Войти
            </Button>
            <div className={classes.linksWrapper}>
              <Link
                href="/"
                style={{
                  color: 'grey',
                  textDecorationColor: 'grey',
                }}
              >
                Обратно в developers
              </Link>
              <Link
                href="/recovery"
                style={{
                  color: 'grey',
                  textDecorationColor: 'grey',
                }}
              >
                Восстановить пароль
              </Link>
              <Link
                href="/signup"
                style={{
                  color: 'grey',
                  textDecorationColor: 'grey',
                }}
              >
                Зарегистрироваться
              </Link>
            </div>
          </Box>
        </div>
        <div className={classes.footerWrapper}>
          <Footer withLinks />
        </div>
      </Box>
    </div>
  );
}

export default Login;
