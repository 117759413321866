import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  Button,
  Popover,
  ListItemIcon,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { If, Then, Else } from 'react-if';
import { removeValue } from '../../utils/storage';
import requestWithAuth from '../../utils/requestWithAuth';
import urls from '../../shared/urls';

const StyledAuthPopover = styled('div')(
  () => ({
    width: '250px',
  }),
);

const LoggerUser = styled(Button)({
  backgroundColor: 'transparent',
  '&&:hover': {
    backgroundColor: 'transparent',
  },
});

const StyledMenuItem = styled(MenuItem)({
  minHeight: '44px !important',
});

function AuthSection({ user, onUserUpdate }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const isMyAddonsSection = location.pathname === '/my-addons';

  const isLoggedIn = !!user;
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogoutOption = async () => {
    requestWithAuth.post(urls.logout);
    removeValue('user');
    handleClose();
    onUserUpdate(null);

    if (isMyAddonsSection) {
      navigate('/');
    }
  };
  const handleLoginOption = async () => {
    navigate('/login');
  };
  return (
    <>
      <If condition={isLoggedIn}>
        <Then>
          <LoggerUser disableRipple onClick={handlePopoverOpen}>
            {user?.full_name}
          </LoggerUser>
        </Then>
        <Else>
          <Button variant="outlined" onClick={handleLoginOption}>Log In</Button>
        </Else>
      </If>

      <Popover
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledAuthPopover>
          <StyledMenuItem disabled>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </StyledMenuItem>
          <StyledMenuItem key="logout" onClick={handleLogoutOption}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </StyledMenuItem>
        </StyledAuthPopover>
      </Popover>
    </>

  );
}

AuthSection.propTypes = {
  user: PropTypes.object,
  onUserUpdate: PropTypes.func,
};

AuthSection.defaultProps = {
  user: null,
  onUserUpdate: _.noop,
};

export default AuthSection;
