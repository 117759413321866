/* globals document */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import ReactDOM from 'react-dom';
import { init as initSentry } from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple, indigo } from '@mui/material/colors';
import 'highlight.js/styles/github.css';
import './index.css';
import './globals';
import { SnackbarProvider } from 'notistack';
import Router from './Router';
import { ApiContext } from './context';
import reportWebVitals from './reportWebVitals';
import api from './api';
import tranfromApi from './utils/transformApi';
import { get as registryGet } from './lib/appRegistry';

const { developersPortalEnabled, sentry } = registryGet('config');

initSentry({
  dsn: sentry.dsnPublic,
  environment: sentry.environment,
  release: sentry.release,
});

const restApi = tranfromApi(api.restApi);
const externalWebHooksApi = tranfromApi(api.externalWebHooksApi);
const webHooksApi = tranfromApi(api.webhooksApi);
const scimApi = tranfromApi(api.scimApi);
const userMetadata = tranfromApi(api.userMetadata);
const importsApi = tranfromApi(api.imports);
externalWebHooksApi.prefix = '/external-webhooks';
webHooksApi.prefix = '/webhooks';
scimApi.prefix = '/scim';
importsApi.prefix = '/imports';

const apiContextValue = {
  rest: restApi,
  'external webhooks': externalWebHooksApi,
  webhooks: webHooksApi,
  scim: scimApi,
  'user metadata': userMetadata,
  imports: importsApi,
};

if (developersPortalEnabled) {
  const addonsApi = tranfromApi(api.addons);
  addonsApi.prefix = '/addons';
  apiContextValue.addons = addonsApi;
}

const theme = createTheme({
  palette: {
    primary: purple,
    secondary: indigo,
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={apiContextValue}>
        <SnackbarProvider>
          <Router addonSectionEnabled={developersPortalEnabled} />
        </SnackbarProvider>
      </ApiContext.Provider>
    </ThemeProvider>

  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
